.fitnessEditorContent{
    padding-top: 3vw;
    padding-bottom: 8vw;
    @media only screen and (max-width: 767px) {
        padding-top: 5vw;
        padding-bottom: 12vw;
    }

    &--row{
        justify-content: center;
        align-items: center;
    }

    &--col{
        display: flex;
        align-items: center;
        flex-direction: column;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        p{ width: 100%;}
        }
    }

    &--image{
        width: 245px;
        height: 220px;
    }
}