// .single-treatment {
//   .imgHoverSection {
//       padding: 0;
//     &:before {
//       background-color: transparent;
//       pointer-events: none;
//       transform: none;
//     }
//   }
// }

.treatmentEditorContent {
  .content {
    width: 70%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    p {
      font-size: 22px;
      color: $pinkText;
      @media only screen and (max-width: 767px) {
        font-size: 1rem;
      }
    }
  }
}

.secondaryContentSection {
    position: relative;
    background-color: $pink;
    padding-top: 10vw;
    padding-bottom: 10vw;
    overflow: hidden;
  &--bgImg{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 100%;
      object-fit: cover;
      @media only screen and (max-width: 767px){
        display: none;
    }
  }

  .secondaryContentRow {
      align-items: center;

    &--col {
        color: $white;
        p{color: $white; font-weight: 100;}

        h1, h2, h3, h4, h5, h6{
            font-weight: 100;
        }
    }
  }
}
