.testimonials {
  width: 100%;
  padding: 120px 0;
  position: relative;
}

.testimonials-container {
  background-color: #fff;
}

.testimonials-slider {
  &__star-rating {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  &__star {
    margin: 0 4px;
  }
  
  &__testimonial {
    max-width: 258px;
    margin: 0 auto;
    min-height: 120px;

    @media(min-width: 501px) {
      max-width: 300px;
    }

    @media(min-width: 768px) {
      max-width: 500px;
    }

    @media(min-width: 991px) {
      max-width: 800px;
    }

    p {
      text-align: center;
      color: $pinkText;
      font-weight: 100;
      font-size: 18px;
      line-height: 140%;

      @media(min-width: 768px) {
        font-size: 22px;
      }
    }
  }

  &__control {
    position: absolute;
    background-color: #F3F1F1;
    padding: 10px;
    height:44px;
    width: 44px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    transition-duration: 200ms;

    img {
      display: block;
      margin: 4px auto;
    }

    &:hover {
      background-color: #dbd9d9;
    }
  }

  &__back {
    left: 0;
    img {
      margin: 4px 9px 4px auto;
    }
  }

  &__next {
    right: 0;
  }
}