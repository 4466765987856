.article {
  position: relative;
  padding: 6em 0 25vw 0;
  // margin-bottom: 3em;
  color: $white;
  background-color: $light;

  @media only screen and (max-width: 767px) {
    padding-top: 3em;
    padding-bottom: 30vw;
  }

  p {
    color: $white;
  }
  .btnArticle {
    position: absolute;
    bottom: 15vw;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 180px;
  }

  &:before {
    content: "";
    display: block;
    padding: 1px;
    background-image: linear-gradient(
      to bottom,
      $secondary 60%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: left;
    background-size: 1px 10px;
    background-repeat: repeat-y;
    position: absolute;
    top: 0;
    bottom: 15vw;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .articleTwoRow {
    position: relative;

    .contentWrap {
      padding: 1em;

      .content {
        width: 100%;
        height: 100%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-image: linear-gradient(to left, #752a6c, #752a6c);

        .image {
          height: 200px;
          overflow: hidden;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .text {
          padding: 4em;
          color: #ffffff;
          p {
            color: #ffffff;
          }
          @media only screen and (max-width: 767px) {
            padding: 3em 1em;
          }

          .btn {
            margin-top: 1em;
            border: 1px solid #ffffff;
            font-style: normal;
            font-weight: 800;
            color: #ffffff;

            &:hover {
              border-color: white;
              background-color: white;
              color: #752a6c;
            }
          }
        }
      }

      &:nth-child(odd) {
        transform: skewY(-6deg);
        .content {
          // background: $primaryGradient;

          .text {
            transform: skewY(6deg);
          }
        }
      }

      &:nth-child(even) {
        transform: skewY(5deg) translate(0, 3em);
        @media only screen and (max-width: 767px) {
          transform: skewY(5deg) translate(0, 0);
        }
        .content {
          // background: $secondaryGradient;
          .text {
            transform: skewY(-5deg);
          }
        }
      }

      .membership {
        background: #752a6c;
      }
      .group-exercise {
        background: $secondaryGradient;
      }
      .swimming {
        background: $twitterGradient;
      }
      .fitness-suites,
      .health-suites {
        background-image: linear-gradient(
          138.87deg,
          #d85a2e 3.3%,
          #e68c2d 105.46%
        );
      }
      .general {
        background: $light;
        color: $dark !important;
        h5 {
          color: $dark;
        }
        p {
          color: $dark !important;
        }

        .btn {
          color: $secondary !important;
          border-color: $secondary !important;

          &:hover {
            background-color: $secondary !important;
            color: $light !important;
          }
        }
      }
    }
  }
}

// =========================
// =========================
// Archive and Single pages
// =========================
// =========================

.post-type-archive-latest-news {
  .news-letter-archive {
    padding-top: 0;
  }

  .article {
    background-color: transparent;
    padding-bottom: 8vw;

    &:before {
      bottom: 0;
    }
  }
}

.single-latest-news {
  .basicPage {
    padding-top: 3vw;
  }
}
