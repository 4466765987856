.signUp, .signUpFrontPage {
    padding-top: 50px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    background: $secondaryGradient;
    max-height: 590px;
    /* margin-top: -8vw; */
    z-index: 1;
  
    > .container {
      color: $light;
      text-align: center;
    }
  
    @media (min-width: 768px) {
      padding-top: 90px;
      padding-bottom: 130px;
      // margin-top: 0;
    }
  
    @media (min-width: 1200px) {
      padding-bottom: 180px;
    }

    &Label{
      margin: 0;
    }
  }
  
  .signUpInner {
    max-width: 848px;
    margin: auto;
  }
  
  .signUpHeading {
    line-height: 24px;
    font-size: 20px;
    max-width: 221px;
    margin: 0 auto 30px;
  
    @media (min-width: 768px) {
      max-width: none;
      line-height: 30px;
      font-size: 24px;
      margin-bottom: 48px;
    }
  }
  
  // .signUpBtn {
  //   width: 100%;
  //   max-width: 200px;
  //   height: 45px;
  //   background: #f09fa3;
    
  //   &:hover{
  //     background: #f09fa3;
  //   }
  // }
  
  .signUpCheckboxWrapper {
    display: flex;
    justify-content: space-between;
    max-width: 280px;
    width: 100%;
    margin: 0 auto 20px;
    align-items: center;
  
    @media (min-width: 768px) {
      margin: 0;
    }

    a{
      color: $white;
    }
  }
  
  .signUpFieldsInline {
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  .signUpInput {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid white;
    color: white;
    padding-bottom: 8px;
    width: 100%;
    margin-bottom: 35px;
    height: 32px;
  
    &:focus {
      outline: 0;
    }
  
    &::placeholder {
      color: white;
      font-weight: 300;
      line-height: 22px;
      font-size: 16px;
    }
  
    @media (min-width: 768px) {
      margin-bottom: 41px;
    }
  
    &Inline {
      @media (min-width: 768px) {
        max-width: 45%;
        margin-bottom: 0;
      }
    }
  }
  
  .signUpFieldsTop {
    @media (min-width: 768px) {
      margin-bottom: 62px;
    }
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    // border: none;
    -webkit-text-fill-color: $white;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  .signUpFrontPage {
      margin-top: -8vw;
      margin-bottom: -5vw;
  }