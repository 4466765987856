.page-template-page-booking {
  background-color: $light;

  .smallBanner {
    &:after {
      display: none;
    }
    .skewShape {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      height: 100vh;
      margin-top: -4.4vw;
      background-color: $white;
      transform: skewY(-5deg);
      overflow: hidden;

      &:after {
        content: "";
        display: block;
        height: 100vh;
        margin-top: -4.4vw;
        background-color: $light;
        transform: skewY(5.5deg);
        transform-origin: left;
      }
    }
  }
}

.bookingForm {
  .content {
    width: 70%;
    font-size: 22px;
    font-weight: 100;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
      width: 100%;
    }
    p {
      color: $pinkText;
    }

    form {
      font-size: 18px;
      font-weight: 500;
      margin: 2rem 0;
      @media only screen and (max-width: 767px) {
        font-size: 1rem;
      }
    }

    .form-group {
      align-items: center;

      label {
        color: $primary;
        font-weight: 900;
        font-style: italic;
      }

      // ===============
      // ===============
      // ===============

      input, textarea,
      select {
        width: 100%;
        border: none;
        // box-shadow: inset 0 1px 3px #ddd;
        border-radius: 4px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 8px;
        padding-bottom: 8px;

        &:focus {
          outline: none;
        }
      }

      input[type="date"] {
        text-transform: uppercase;
      }
    }
  }

  //=================
  //=================
  //=================
  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    // border: none;
    -webkit-text-fill-color: $text;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
}

//============
//============
//============
// Input that have images
.inputImg{
    position: relative;
    &:after{
        content:"";
        font-family: 'FontAwesome';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1em;
        width: 30px;
        background-color: white;
        pointer-events: none;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        color: $secondary;
    }
}

.datePicker:after{
    content:"\f073";
}

.timeRequiered:after{
    content:"\f017";
}

.selectBox{
    transition: all ease-in-out 100ms;
    &:after{
        transition: all ease-in-out 100ms;
        content:"\f0d7";
    }
}

.rotate:after{
    transform: rotate(-180deg);
}
//============
//============
//============

select {
  appearance:none;
  }