.form {

    &__input,
    &__label {
        padding: 12px;
        border: 1px solid #ccc;
        height: 100%;
        -webkit-text-fill-color: black !important;
    }

    &__input {
        width: 100%;
    }

    &__label {
        width: auto;
        min-width: 100px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;

        @media (min-width: 576px) {
            min-width: 150px;
        }
    }

    &__first-name,
    &__date {
        border-bottom: 0;

        @media (min-width: 576px) {
            border-right: 0;
            border-bottom: 1px solid #ccc;
        }
    }
}

.cancellation-checkboxes {
    .wpcf7-form-control-wrap {
        width: 100%;
        border: 1px solid #ccc;
        display: flex;
        justify-content: center;

        .wpcf7-form-control {
            margin: auto;
        }
    }
}