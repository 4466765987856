// Vars
@import "sassMagic";

/* Bootstrap 4 */
// Required
@import "~bootstrap/scss/bootstrap";
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import "modules/datePicker";

// Custom
@import "global";
@import "header";
@import "modules/mainNav";
@import "modules/imgHover";
@import "frontPage";
@import "homeHero";
@import "contact";
@import "modules/articles";
@import "modules/testimonials";
@import "pages/treatments";
@import "modules/priceTable";
@import "pages/packages";
@import "pages/facilities";
@import "pages/fitness";
@import "pages/faq";
@import "pages/contact";
@import "pages/basicPage";
@import "pages/booking";
@import "termsAndPrivacy";
@import "404";
@import "footer";
@import "form";


// This Recaptcha Hidden. Unhide later
.grecaptcha-badge{
    display: none !important;
}

// Temp hide book now btn

// a[href*="phorest.com/book/salons/manaspa"]{
//     display: none;
// }