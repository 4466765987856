.imageHover {
    background: $primaryGradient;
    transform: skewY(5deg);
    padding: 0;
    z-index: 1;
    overflow: hidden;
  
    .container-fluid {
      // transform: skewY(-8deg);
      padding: 0;
      .hoverContent {
        padding: 0;
      }
      .hovereffect {
        width: 100%;
        height: 100%;
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;
        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          background-color: transparent;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            overflow: visible;
            &:hover {
              background-color: rgba($primary, 0.4);
            }
          }
        }
        img {
          width: 100%;
          height: 20vw;
          object-fit: cover;
          display: block;
          position: relative;
          transition: all 155ms ease-in;
          @media only screen and (max-width: 991px) {
            height: 24vw;
          }
          @media only screen and (max-width: 767px) {
            height: 30vw;
            filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
            filter: grayscale(0.7) blur(3px);
            transform: scale(1.2);
          }
          @media only screen and (max-width: 557px) {
            height: 50vw;
          }
        }
        &:hover img {
          filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
          filter: grayscale(0.7) blur(3px);
          transform: scale(1.2);
        }
        h5 {
          margin-top: 5.5vw;
          @media only screen and (max-width: 767px) {
            margin-top: 8vw;
          }
          @media only screen and (max-width: 577px) {
            margin-top: 12vw;
          }
        }
        a.btn {
          display: inline-block;
          margin: 2em 0 0 0;
          transform: scale(0.7);
          transition: all 155ms ease-in;
          opacity: 0;
          filter: alpha(opacity = 0);
        }
  
        h5 {
          transform: scale(0.7);
          transition: all 155ms ease-in;
          opacity: 0;
          filter: alpha(opacity = 0);
          color: $white;
        }

        a.btn, h5{
          @media only screen and (max-width: 767px){
            opacity: 1;
            transform: scale(1) skewY(-4deg);
          }
        }
        &:hover {
          a.btn,
          h5 {
            opacity: 1;
            filter: alpha(opacity = 100);
            transform: scale(1) skewY(-4deg);
          }

          .overlay{
            background-color: rgba(black, 0.4);
            transition: all ease-in-out 255ms;
          }
        }
      }
    }
  }