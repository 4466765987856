.heroSkewShape {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: calc(100% - 8vw);
  left: 0;
  margin-top: -10px;
  transform: skewY(5deg);
  transform-origin: 0 0;
  overflow: hidden;
  background: #752a6c;

  &:after {
    content: "";
    display: block;
    position: static;
    width: 100%;
    height: 100vh;
    background: $white;
    transform: skewY(-10deg);
  }
}

//==========//

.homeAbout {
  background-color: $white;
  text-align: center;
  padding-top: 3vw;
  padding-bottom: 3vw;

  .row {
    justify-content: center;
    h3 {
      color: #752a6c;
    }

    .btn {
      margin-top: 1em;
    }
  }
}

// ================ //
// About logo grid
// ================ //

.borderedGrid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 2em 0 0 0;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  &--box {
    border: 1px solid #d8d8d8;
    min-height: calc(200px - 1vw);
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.8rem;
    padding: 0 2em;
    transition: box-shadow ease-in-out 255ms;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 2.5vw;
    }

    &:hover {
      box-shadow: 0 5px 15px rgba($black, 0.3);
    }
  }
}

.btnWrap {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .btn {
    @media only screen and (max-width: 340px) {
      font-size: 14px;
    }
  }
}

// ================ //
// ================ //
// ================ //
.imgHoverSection {
  padding: 0;
  background-color: #dd1c72;
}

.home {
  .imgHoverSection {
    background-color: transparent;
    position: relative;
    padding-bottom: 0;
    padding-top: 3em;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 4vw;
      right: 0;
      background-color: #f3f1f1;
      transform: skewY(-5deg);
      // transform-origin: left;
    }
  }
  .imgHoverSection {
    z-index: 2;
  }
}

.safety-notice {
  margin-top: -75px;
  z-index: 9;
}
