$navBreak: "only screen and (max-width : 1199px)";

// HEADER
header {
  opacity: 1;
  transform: translateY(0);
  padding: 1em 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  transition: all 0.3s ease-out;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  width: 100%;

  
}

.navbar {
  padding: 0;

  // Hamburger
  .navbar-toggler {
    float: right;
    border: none;
    padding-right: 0;
    &:active,
    &:focus {
      outline: none;
    }

    .navbar-toggler-icon {
      width: 24px;
      height: 17px;
      background-image: none;
      position: relative;
      border-bottom: 1px solid #000;
      transition: all 300ms linear;

      &:after,
      &:before {
        width: 24px;
        position: absolute;
        height: 1px;
        background-color: #000;
        top: 0;
        left: 0;
        content: "";
        z-index: 2;
        transition: all 300ms linear;
      }

      &:after {
        top: 8px;
      }

      //remove bootsrap hamburger icon
      i.fa {
        display: none;
      }
    }

    &[aria-expanded="true"] .navbar-toggler-icon {
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: translateY(8px) rotate(-45deg);
      }
      border-color: transparent;
    }
  }

  //Navigation
  .navbar-nav {
    width: 100%;
    @media #{$navBreak} {
      width: 280px;
      margin: 0 auto;
    }
    // justify-content: space-between;

    .nav-item {
      position: relative;
      transition: all 200ms linear;
      & + .nav-item {
        // margin-right: 2em;
        @media #{$navBreak} {
          margin-right: 0;
          margin-left: 0 !important;
          padding-left: 0 !important;
          border-top: 1px dotted white;
        }
      }
      &:first-of-type {
        // margin-right: 2em !important;
        margin-left: 0 !important;
        @media #{$navBreak} {
          margin-right: 0 !important;
          padding-left: 0 !important;
        }
      }

      // hover underline effect
      &:after {
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        content: "";
        background-color: $secondary;
        opacity: 0;
        transition: all 200ms linear;
      }
      &:hover:after {
        bottom: 0;
        opacity: 0; // Should be 1 to see the animated line. emily said to get rid of it;
      }
      &.active:hover:after {
        opacity: 0;
      }

      //Nav Link
      .nav-link {
        font-weight: 900;
        font-size: 14px;
        text-transform: uppercase;
        color: #3c3c3b;
        transition: all 200ms linear;

        position: relative;
        padding: 5px 0;
        display: inline-block;

        @media #{$navBreak} {
          display: flex;
          justify-content: space-between;
          color: white;
        }
      }

      &:hover .nav-link {
        color: $secondary;
        @media #{$navBreak} {
          color: $white;
        }
      }

      &.active .nav-link {
        color: #777;
      }
    }
  }
}

/* #Primary style
  ================================================== */

.nav-item {
  .dropdown-toggle {
    &:after {
      content: "\f0d7";
      font-family: "FontAwesome";
      color: $secondary;
      border: none;
      vertical-align: 0;
      transition: all 200ms linear;
      display: inline-block;
      @media #{$navBreak} {
        color: white;
      }
    }
  }

  &.show .dropdown-toggle:after {
    transform: rotate(-180deg);
  }

  .dropdown-menu {
    left: 50%;
    transform: translateX(-50%) translate3d(0, 10px, 0);
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    display: block;
    padding: 0 !important;
    padding-bottom: 20px !important;
    margin: 0;
    transition: all 200ms linear;
    border-radius: 0;
    background-color: $white;
    @media #{$navBreak} {
      left: unset;
      transform: unset;
      padding-bottom: 0 !important;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100px;
      margin-bottom: 17px;
      background: url(../img/nav/menuImg.jpg) no-repeat center/cover;
      @media #{$navBreak} {
        display: none;
      }
    }

    &:after {
      content: "";
      display: table;
      clear: both;
      width: 100%;
      height: 20px;
      background-color: $white;
      position: absolute;
      bottom: -10px;
      left: 0;
      transform: skewY(-3deg);
      z-index: -1;
      box-shadow: 0 1px 13px -7px grey;
      @media #{$navBreak} {
        display: none;
        box-shadow: none;
      }
    }
  }
  &.show .dropdown-menu {
    opacity: 1;
    visibility: visible;
    max-height: 999px;
    transform: translateX(-50%) translate3d(0, 0px, 0);
    @media #{$navBreak} {
      transform: translate3d(0, 0px, 0);
    }
  }

  &:last-child {
    .dropdown-menu {
      left: 100%;
      transform: translateX(-100%) translate3d(0, 10px, 0);
      @media #{$navBreak} {
        left: unset;
        transform: unset;
      }
    }

    &.show .dropdown-menu {
      transform: translateX(-100%) translate3d(0, 0px, 0);
      @media #{$navBreak} {
        transform: translate3d(0, 0px, 0);
      }
    }
  }
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  width: 14rem;
  font-size: 13px;
  letter-spacing: 1px;
  color: $text;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  padding: 3px 15px;
  color: $secondary;
  text-align: center;
  border-radius: 2px;
  transition: all 200ms linear;
  letter-spacing: normal;
  @media #{$navBreak} {
    text-align: inherit;
    color: darken($white, 10%);
  }
  &:hover,
  &:focus,
  &.active {
    color: $primary;
    background-color: darken($white, 5%);
    @media #{$navBreak} {
      color: $white;
      background-color: transparent;
    }
  }
}

/* #Media
  ================================================== */

@media #{$navBreak} {
  .nav-item {
    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    background-color: transparent;
  }
}

/* ================= */
// Nav Layout
/* ================ */

nav {
  flex-wrap: wrap !important;
  @media #{$navBreak} {
    flex-wrap: nowrap;
  }
  .book-now {
    width: 100%;
    margin-bottom: 1em;
    @media #{$navBreak} {
      width: auto;
      margin: 0;
      order: 2 !important;
      flex: 1;
      text-align: right;
    }
  }

  .navbar-brand {
    margin-top: -2em;
    background-image: url("../../img/logo-dark.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    @media #{$navBreak} {
      margin-top: 0;
      order: 1 !important;
    }

    @media only screen and (max-width: 991px) {
      width: 120px;
    }

    img {
      visibility: hidden;
    }
  }

  .navbar-toggler {
    @media #{$navBreak} {
      order: 3 !important;
    }
  }

  .navbar-collapse {
    @media #{$navBreak} {
      order: 4 !important;
      background: $secondaryGradient;
      padding: 0 1em;
      position: fixed;
      top: 100px;
      left: 0;
      width: 100%;
      box-shadow: 0 20px 20px -25px grey;

      min-height: calc(100vh - 74px);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity ease-in-out 255ms;
      &.show,
      &.collapsing {
        opacity: 1;
      }

      @media only screen and (max-width: 450px) {
        top: 110px;
      }
    }
  }
}


// =========================

.latestNewsLink {
  color: $secondary;
  &:hover{
    color: $secondary;
  }

  @media #{$navBreak} {
    display: none;
  }
}

/* ===================== */
// Home page navigation
/* ==================== */

header.transparentHeader {
  background-color: transparent;
  box-shadow: none;

  .latestNewsLink {
    color: $white;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    color: $white;
    &:after {
      color: $white;
    }
    &:hover {
      color: $secondary;
      &:after {
        color: $secondary;
      }
    }
  }

  .navbar .navbar-nav .nav-item.show > .nav-link {
    color: $secondary;
    &:after {
      color: $secondary;
    }
  }

  .navbar .navbar-nav .nav-item:after {
    background-color: $secondary;
  }

  .navbar-toggler {
    .navbar-toggler-icon {
      border-bottom: 1px solid $white;

      &:after,
      &:before {
        background-color: $white;
      }
    }

    &[aria-expanded="true"] .navbar-toggler-icon {
      border-color: transparent;
    }
  }

  .navbar-brand {
    background-image: none;
    img {
      visibility: visible;
    }
  }
}
