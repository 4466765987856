// =============
// =============
// =============
// =============

.packages {
  padding-bottom: 0;
  &--content {
    position: relative;
    background-color: white;
    padding-top: 5vw;
    padding-bottom: 5vw;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
      padding-top: 10vw;
      padding-bottom: 10vw;
    }
  }

  &--row {
    flex-direction: row-reverse;
  }

  &--bgImg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  &--col {
    color: black;
    font-weight: 100;
    strong {
      font-weight: 500;
    }
    p {
      color: black;
    }
    h2 {
      color: #752a6c;
      font-weight: 100;
      font-size: 36px;
      @media only screen and (max-width: 767px) {
        font-size: 28px;
      }
    }

    ul {
      margin: 1rem 0;
      font-weight: 500;
    }
  }

  &--table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    border-bottom: 2px dotted $white;
    &:first-of-type {
      margin-top: 1.5rem;
      border-top: 2px dotted $white;
    }
    @media only screen and (max-width: 340px) {
      width: 100%;
    }
  }

  &--day,
  &--price {
    padding: 0.7rem 0;
    font-size: 18px;
    font-weight: 500;
  }

  &--price {
    font-weight: 900;
  }

  //   ODD
  &--content__odd {
    .packages {
      &--row {
        flex-direction: row;
      }

      &--bgImg {
        right: 0;
        left: auto;
      }
    }
  }
}

// =============
// =============
// =============
// =============

.page-template-page-membership {
  .testimonials-container.skewRight {
    transform: none;

    .container {
      transform: none;
    }
  }
}
