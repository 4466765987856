.faq {
  padding-top: 0;
  padding-bottom: 8vw;
  h1 {
    margin-bottom: 1vw;
  }

  #accordion, #accordion2 {
    .card {
      border: 1px solid #dadce0;
      border-radius: 0;

      .card-header {
        padding: 0;
        border: none;
        // border: 0.5px solid #dadce0;

        button {
          width: 100%;
          padding: 1.6em 2em 1.4em 2em;
          margin: 0;
          background-color: #ffffff;
          color: $text;
          text-align: left;
          border-radius: 0;
          border: none;
          border-bottom: none;
          transform: none;
          &:after {
            content: "\f077";
            font-family: "FontAwesome";
            font-style: initial;
            color: $secondary;
            float: right;
            transform: rotate(0);
            transition: all ease-in-out 255ms;
          }

          &.collapsed:after {
            transform: rotate(180deg);
          }

          &:hover {
            transform: scaleY(1);
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      .card-body {
        background-color: #e8f0fe;
      }

      &:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        // background-color: $blue;
      }
      &:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        // background-color: $blue;
      }
      &:first-of-type > .card-header > h5 > button {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        // background-color: $blue;
      }
      &:last-of-type > .card-header > h5 > button {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        // background-color: $blue;
      }
    }
  }
}
