.hero {
  padding: 0;
  position: relative;
  overflow: hidden;

  .heroOverlay{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 50vw;
    pointer-events: none;

    @media #{$navBreak} {
      display: none;
    }
  }

  .slick-prev:before, .slick-next:before{
    font-family: 'FontAwesome';
    color: $info;
    opacity: 1;
    @media only screen and (max-width: 420px) {
      display: none;
    }
  }

  .slick-prev:before {
    content: "\f053";
  }
  .slick-next:before {
    content: "\f054";
  }

  .heroImg {
    .heroContent {
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden;

      .overlay {
        background-color: rgba(0, 0, 0, 0.3);
        display: block;
      }

      img,
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      //Styling of hero content
      > .container {
        position: absolute;
        right: static;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media only screen and (max-width: 767px) {
          top: 30%;
          transform: translate(-50%, -30%);
        }
        @media only screen and (max-width: 340px) {
          top: 30%;
          transform: translate(-50%, -30%);
        }

        @media only screen and (max-height: 560px) {
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .content {
          width: 600px;
          @media only screen and (max-width: 600px) {
            width: 100%;
          }

          p{
            color: $white;
            margin: 0;
          }
    
          h1, h2, h3, h4, h5, h6 {
            font-weight: 900;
            color: $white;
          }
    
          h1 {
            // margin: 0 0 0.5em 0;
            font-size: 52px;
            @media only screen and (max-width: 767px) {
              font-size: 30px;
            }
          }
          h5 {
            font-size: 18px;
            @media only screen and (max-width: 767px) {
              font-size: 14px;
            }
          }

          img {
            width: auto;
            height: auto;
          }
        }
      }
    
    }

    ///===========SLICK
    .heroSlider {
      img.img-fluid {
        width: 100%;
        object-fit: cover;
      }
    }

    .js .slider-single > div:nth-child(1n + 2) {
      display: none;
    }

    .js .slider-single.slick-initialized > div:nth-child(1n + 2) {
      display: block;
    }
  }

  .slideNavContainer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    .slider-nav {
      .thumnail {
        outline: none;

        .thumnailWrap {
          position: relative;
          overflow-x: hidden;
          border: 4px solid white;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          margin-left: 1.2em;
        }
        
        img,
        video {
          width: 100%;
          height: 150px;
          object-fit: cover;
          @media only screen and (max-width: 768px) {
            height: 100px;
          }
          @media only screen and (max-width: 340px) {
            height: 60px;
          }
        }
      }
    }
  }

  .slider-nav h3::before {
    content: "";
    display: block;
    padding-top: 75%;
  }

  .slider-nav h3 span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slider-nav .slick-slide {
    cursor: pointer;
  }

  .slick-slide {
    position: relative;
    .overlay {
      display: none;
      background-color: rgba($black, 0.4);
    }

    &.is-active,
    &:hover {
      .overlay {
        display: flex;
        align-items: flex-end;
        color: $white;
        padding: 1em;
        .thimnailText{          
          h1, h2, h3, h4, h5, h6{
            font-size: 18px;
            font-weight: 500;
          }

          @media only screen and (max-width: 768px) {
          display: none;
          }
        }
      }
    }
  }

  .priceContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 767px) {
      top: 70%;
      left: 50%;
      right: unset;
      transform: translate(-50%, -70%);
      width: auto;
    }

    @media only screen and (max-width: 575px) {
      top: initial;
      bottom: 18vw;
      right: 0;
      transform: none;
    }

    .price {
      float: right;
      width: 350px;
      color: $light;
      @media only screen and (max-height: 560px) {
        display: none;
      }
      @media only screen and (max-width: 575px) {
        width: 200px;
        h5 {
          font-size: 1em;
        }
        h1 {
          font-size: 2em;
          padding: 0.1em 0;
        }

        .priceContent .priceText .joinNowBtnWrap {
          bottom: -18vw;
        }
      }

      @media only screen and (max-width: 767px) {
        margin-top: 2em;
      }

      /* IE10+ CSS styles go here */
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        svg {
          transform: scale(1.5);
        }
      }

      .priceContent {
        position: relative;
        .priceText {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          @media only screen and (max-width: 1199px) {
            top: 30%;
            transform: translate(-50%, -30%);
          }
          h5 {
            line-height: 0.5;
          }

          .joinNowBtnWrap {
            position: absolute;
            left: 50%;
            bottom: -70px;
            transform: translate(-50%, 0);
            @media only screen and (max-width: 991px) {
              bottom: -90px;
            }

            @media only screen and (max-width: 767px) {
              bottom: -22vw;
            }
            @media only screen and (max-width: 577px) {
              bottom: -28vw;
            }
          }
        }
      }
    }
  }

  .sliderControl {
    position: absolute;
    top: 90%;
    left: 0;
    transform: translate(0, -90%);
    width: 100%;

    .btn {
      background: transparent;
      border-color: transparent;

      &:hover {
        border: 1px solid transparent;
      }
    }
  }
}
