.contactEditer {
  padding-top: 0;
  padding-bottom: 8vw;

  .contactContent {
    justify-content: center;
    align-items: flex-start;

    &--map{
        display: flex;
        align-items: center;
        @media only screen and (max-width: 767px) {
            justify-content: center;
        }
        img{
            width: 430px;
            @media only screen and (max-width: 767px) {
                width: 300px;
                margin-bottom: 1rem;
            }
        }

        a{
          color: lighten($text, 20%);
        }
    }

    &--location {
      &__inner {
        overflow: hidden;
        transform: skewY(5deg);
        font-size: 18px;
        background: $secondaryGradient;
        padding: 3vw 1vw;
        text-align: center;

        @media only screen and (max-width: 768px) {
          font-size: 1rem;
        }
        @media only screen and (max-width: 767px) {
          padding: 10vw 2vw;
        }
        p {
          color: $white;
        }

        a{
          color: $info;
        }

        .btn{
          color: $primary;
        }

        .unskew{
            transform: skewY(-5deg);
        }
      }
    }
  }
}

.page-template-page-contact{
  background-color: $light;
  .smallBanner.smallerBanner:after{
    background-color: $light;
  }
  .form-group{
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-direction: column !important;

    div, label{
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}