// .home{
//   .logoSlider{
//     margin-top: -5vw;
//   }
// }

.logoSlider {
  /* padding-top: 9em; */
  background: white;
  margin-top: -6vw;
  z-index: 5;
  position: relative;

  .row {
    justify-content: center;
    align-items: center;
    color: $light;
    text-align: center;

    .row {
      justify-content: flex-start;

      .logos {
        //   // padding: 0;
        //   // margin: 0 1em;

        //   @media only screen and (max-width: 767px) {
        //     // margin: 0;
        //     // padding: 1em;
        //   }

        //   // img {
        //   //   @media only screen and (max-width: 767px) {
        //   //     width: 60%;
        //   //   }
        //   //   @media only screen and (max-width: 340px) {
        //   //     width: 80%;
        //   //   }
        //   // }
      }
    }

    .oparatedBy {
      padding: 1em;

      h4 {
        margin-bottom: 1em;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 3em;
        h4 {
          margin-bottom: 0;
        }
      }
    }
  }
}

footer {
  margin-top: auto;
  padding: 1em 0;
  background-color: #752a6c;
  color: $light;
  z-index: 1;
  position: relative;

  .copyright,
  b {
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      & + li {
        margin-top: 5px;
      }

      font-weight: 600;

      b {
        font-weight: bold;
      }

      a {
        color: $light;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -3px;
          right: 0;
          padding: 0.5px;
          background-color: $light;
          opacity: 0;
          transition: opacity ease-in-out 200ms;
        }
        &:hover {
          text-decoration: none;
          color: $light;

          &:after {
            opacity: 1;
            transition: opacity ease-in-out 200ms;
          }
        }
      }
    }
  }

  a,
  a:hover {
    color: white;
  }

  .topRow {
    .socialMedia {
      width: 120px;
      a {
        margin-top: 0.5em;
      }
    }

    .openingHours {
      @media only screen and (max-width: 400px) {
        margin: 1rem 0;
      }
    }
  }

  .bottomRow {
    margin-top: 2em;
  }

  p {
    color: $white;
  }
}

footer * {
  font-family: "proxima nova";
}

.footer_text {
  line-height: 4rem;
  text-align: center;
}

.cv-life-logo {
  height: 61px;
}

.gocv-logo {
  height: 75px;
}

.footerSocial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  @media only screen and (max-width: 767px) {
    align-items: flex-start;
  }
  .socialMedia {
    width: 120px;
    margin-bottom: 1em;
  }

  .logoWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      max-width: 100%;
      // margin-right: 2em;
    }
  }
}

.copyright {
  padding-top: 1rem;
}
