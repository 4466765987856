.priceTable {
  padding-top: 0;
}

.priceRow {
  &--part1 {
    background: $secondaryGradient;
    padding: 2rem;
    color: $white;
    text-align: center;

    img {
      width: 163px;
      height: 29px;
    }

    h2 {
      font-size: 22px;
      font-style: italic;
      margin: 0.5rem 0;
    }
    p {
      color: $white;
      font-size: 14px;
    }
  }

  &--part2 {
    padding: 2rem 1rem;
    &__priceContent {
      display: flex;
      justify-content: space-between;
      padding: 0.8rem 0;
      border-bottom: 1px dotted $secondary;
      font-size: 18px;
      font-weight: 900;

      .time {
        color: $primary;
      }
      .price {
        color: $secondary;
      }
    }
  }

  &--col {
    // padding: 0;
    margin-bottom: 2rem;
    overflow: hidden;
    &__wrap {
      background-color: $light;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &:nth-child(3n + 1),
    &:nth-child(3n + 3) {
      transform: skewY(6deg);

      .unskew {
        transform: skewY(-6deg);
      }
    }

    &:nth-child(3n + 2) {
      transform: skewY(-6deg);

      .unskew {
        transform: skewY(6deg);
      }
    }

    @media only screen and (max-width: 991px){
        &:nth-child(odd){
            transform: skewY(6deg);

            .unskew {
              transform: skewY(-6deg);
            }
        }
        &:nth-child(even){
            transform: skewY(-6deg);

            .unskew {
              transform: skewY(6deg);
            }
        }
    }
    @media only screen and (max-width: 767px){
        &:nth-child(even){
            transform: skewY(6deg);

            .unskew {
              transform: skewY(-6deg);
            }
        }
    }
  }
}
