html,
body {
  font-family: "Roboto", sans-serif;
}

body {
  font-family: "Proxima Nova", "Roboto", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0;
}

.thin {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 100;
    // font-style: italic;
  }
}

p {
  margin: 0.5em 0;
  color: #4a4a4a;
  line-height: 22px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($dark, 0.5);
}

.clearFix:before,
.clearFix:after {
  content: "";
  display: table;
}

.clearFix:after {
  clear: both;
}

section {
  padding: 3em 0;
}

.btn,
.wp-block-button__link {
  min-width: 120px;
  padding: 0.5em 1em;
  position: relative;
  // border: 2px solid transparent;
  border: none;
  border-radius: 2px;
  overflow: hidden;
  font-weight: 500;
  font-style: italic;
  color: $light;
  cursor: pointer;

  &:hover {
    border-color: $light;
    color: $white;
  }

  &.btn-primary {
    color: $primary;
    background-image: linear-gradient(
      to right,
      #fcc93e,
      #f9b600,
      #eb8d1b,
      #fcc93e
    );
    background-size: 300% 100%;
    transition: all 255ms ease-in-out;

    &:hover {
      transition: all 255ms ease-in-out;
      background-position: 110% 0;
    }
  }

  &.btn-secondary,
  &.wp-block-button__link {
    background-color: #752a6c;
    border-radius: 0px;
    // background: $secondaryGradient;

    transition: all 255ms ease-in-out;

    &:hover {
      background-color: #5d3059;
    }
  }

  &.btn-info {
    background-color: #752a6c;
    // background: $secondaryGradient;

    transition: all 255ms ease-in-out;

    &:hover {
      background-color: #5d3059;
    }
  }
}

a {
  text-decoration: none;
  color: $secondary;
  transition: color ease-in-out 155ms;
  &:hover {
    color: darken($secondary, 10%);
  }
}

svg {
  max-width: 100%;
}

img {
  max-width: 100%;
}

// SOCIAL MEDIA
.socialMedia {
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: 1px solid $light;
    border-radius: 50%;
    overflow: hidden;

    &.mail:hover {
      border-color: #d32e2a;
      background-color: #d32e2a;
    }

    &.twitter:hover {
      border-color: #1da1f2;
      background-color: #1da1f2;
    }

    &.facebook:hover {
      border-color: #3b5998;
      background-color: #3b5998;
    }

    &.instagram:hover {
      border-color: #58585b;
      background: #d6249f;
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
    }

    &.phone:hover {
      border-color: #01c476;
      background: #01c476;
    }

    svg {
      width: 12px;
      height: 15px;

      .icon {
        fill: $light;
      }
    }
  }
}

// ==========
// ==========
// ==========

.smallBanner {
  position: relative;
  overflow: hidden;
  min-height: 600px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media only screen and (max-width: 768px) {
    min-height: 400px;
  }
  @media only screen and (max-width: 767px) {
    min-height: 250px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: 100vh;
    margin-top: -4.4vw;
    background-color: $white;
    transform: skewY(-5deg);
  }

  &--title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }

  &--heading {
    font-size: 52px;
    font-weight: 900;
    font-style: italic;
    color: $white;
    @media only screen and (max-width: 768px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 30px;
    }
  }

  &.smallerBanner {
    background: #752a6c;
    min-height: 350px;
    @media only screen and (max-width: 1280px) {
      min-height: 330px;
    }
    @media only screen and (max-width: 1024px) {
      min-height: 320px;
    }
    @media only screen and (max-width: 768px) {
      min-height: 290px;
    }
    @media only screen and (max-width: 767px) {
      min-height: 160px;
    }
    &:after {
      transform: skewY(5deg);
    }
  }
}

// ==========
// ==========
// ==========
.skewRight {
  transform: skewY(5deg);
  padding: 6rem 0;
  > .container,
  .unskew {
    transform: skewY(-5deg);
  }
}

.skewLeft {
  transform: skewY(-5deg);
  padding: 6rem 0;

  > .container,
  .unskew {
    transform: skewY(5deg);
  }
}

// ========================
// ========================
// ========================
.editorContent {
  padding-top: 0;
  padding-bottom: 5vw;
  @media only screen and (max-width: 340px) {
    padding-top: 2em;
  }

  &--content {
    width: 80%;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
    .siteHeading {
      margin-bottom: 0.5rem;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 22px;
      font-weight: 100;
      color: $pinkText;
    }
  }

  ul {
    margin: 1rem 0;
  }
}

.siteHeading {
  font-size: 52px;
  font-weight: 900;
  font-style: italic;
  color: $pinkText;
  @media only screen and (max-width: 767px) {
    font-size: 40px;
  }
}
