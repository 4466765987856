.facilitiesEditorContent{
    @media only screen and (max-width: 767px) {
        padding-bottom: 10vw;
    }
}

// =============================
// =============================
// =============================
.facilitiesSlider{
    padding: 0;
    position: relative;
    height: calc(100vh - 119px);
    overflow: hidden;
    // &--slider{

    // }

    // &--imageWrap{

    // }
    &--image{
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    &:before{
        content: '';
        z-index: 1;
        position: absolute;
        top: -100%;
        right: 0;
        left: 0;
        bottom: 90%;
        background-color: $white;
        transform: skewY(5deg);
    }
    &:after{
        content: '';
        position: absolute;
        top: 99%;
        right: 0;
        left: 0;
        bottom: -100%;
        background-color: $white;
        transform: skewY(5deg);
    }
}

// Styling the default Slick dots

.slick-dots {
    bottom: 188px;
    // transform: skewY(-5deg);
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 1rem 0;
	list-style-type: none;
	
		li {
			margin: 0 0.25rem;
            button {
                display: block;
                width: 1rem;
                height: 1rem;
                padding: 0;
                border: 1px solid $white;
                border-radius: 100%;
                background-color: transparent;
                text-indent: -9999px;
            }
		}
	
		li.slick-active button {
			background-color: $white;
		}
	
}
//================
//================
//================
.facilities{
    background-color: $pink;
    @media only screen and (max-width: 767px) {
        padding-top: 10vw;
        padding-bottom: 10vw;
    }

    &--heading{
        color: $white;
        text-align: center;
        font-size: 24px;
        font-style: italic;
        font-weight: 900;
        margin-bottom: 3vw;
        @media only screen and (max-width: 767px) {
            margin-bottom: 10vw;
        }
    }

    &--col{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2vw 3vw;
        font-size: 18px;
        color: $white;
        @media only screen and (max-width: 768px) {
            font-size: 1rem;
        }
    }

    &--image{
        width: 70px;
        height: 58px;
        margin-bottom: 1vw;
    }
}