.basicPage{
    padding-top: 0;
    padding-bottom: 8vw;

    h4{
        color: $pinkText;
        margin: 2rem 0;
        font-weight: 500;
    }

    h5{
        color: $primary;
        margin-top: 1em;
    }

    h6{
        color: $secondary;
        margin: 2rem 0;
        font-weight: 500;
    }
}