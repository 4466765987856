header{
    .headerInner{
        justify-content: space-between;
        align-items: center;

        .logoWrap{

        }

        .navigationWrap{
            .navigationWrapRow{
                .extraLinks{
                    margin-bottom: 1em;
                    a.normalLink{
                        color: $text;
                        text-decoration: none;
                        &:hover{
                            color: $secondary;
                        }

                        &.loginLink{
                            font-weight: 900;
                            color: $secondary;
                            font-style: italic;
                            &:hover{color: $text;}
                            span{
                                margin-right: 0.5em;
                                font-size: 1.1em;
                                font-style: normal;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}


// ============
// ============
// ============
.siteMap {
    position: absolute;
    top: calc(128px + 1em);
    width: 100%;
    padding: 1em 0;
    z-index: 1;
    mix-blend-mode: hard-light;
    @media only screen and (max-width: 575px){
      top: calc(70px + 1em);
      font-size: 12px;
    //   display: none;
    }
  
    &.noSiteMap {
      display: none;
    }
  
    * {
      color: $light;
    }
  }